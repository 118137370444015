<template>
  <v-container class="px-md-0" :fluid="isSafari">
    <v-row class="justify-center justify-md-left" justify-md="space-between">
      <v-col order="2" order-md="1" class="text-center text-md-left px-0" cols="12" md="5">
        <span class="text-caption">© containerdienst.de | {{ t('service_from_epd') }}</span>
      </v-col>

      <v-col order="1" order-md="2" cols="12" md="3" class="pl-md-0">
        <v-row class="justify-center justify-xl-start">
          <v-col v-for="(link, index) of linkTree" :key="index" cols="2">
            <nuxt-link :href="link.url" target="_blank">
              <v-img :height="smAndDown ? '40px' : '30px'" :src="link.icon" :alt="link.name" />
            </nuxt-link>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        order="3"
        cols="12"
        md="4"
        class="pr-md-0"
        :class="{ 'd-flex justify-md-end': !smAndDown }">
        <v-divider v-if="smAndDown" class="mt-1 mb-2 footer-divider" />

        <v-row justify="center" justify-md="end">
          <v-col class="text-center text-md-right" cols="4" md="3" xl="2">
            <NuxtLink to="/impressum">
              <span class="text-caption">{{ t('imprint') }}</span>
            </NuxtLink>
          </v-col>

          <v-col class="text-center" cols="4" md="3" xl="2">
            <NuxtLink to="/agb">
              <span class="text-caption">{{ t('agb') }}</span>
            </NuxtLink>
          </v-col>

          <v-col class="text-center pr-md-0 text-md-right" cols="4" md="3" xl="2">
            <NuxtLink to="/datenschutzerklaerung">
              <span class="text-caption">{{ t('privacy') }}</span>
            </NuxtLink>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

import iconFacebook from '@/assets/icons/footer/footer_facebook.svg'
import iconLinkedin from '@/assets/icons/footer/footer_linkedin.svg'
import iconXing from '@/assets/icons/footer/footer_xing.svg'

const { smAndDown } = useDisplay()
const { isSafari } = useDevice()

const linkTree = [
  { icon: iconFacebook, url: 'https://www.facebook.com/entsorgung.de', name: 'Facebook' },
  {
    icon: iconLinkedin,
    url: 'https://de.linkedin.com/organization-guest/company/entsorgung-punkt-de-gmbh',
    name: 'LinkedIn',
  },
  { icon: iconXing, url: 'https://www.xing.com/pages/entsorgungpunktdegmbh', name: 'Xing' },
]
const { t } = useI18n()
</script>
<style lang="scss" scoped>
.footer-divider {
  border: 1px solid rgb(var(--v-theme-border));
}
</style>
